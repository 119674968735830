import React from "react";
import { useTranslation } from "react-i18next";

const Footer: React.FC = () => {
  const { t } = useTranslation();
  return (
    <footer className="bg-gray-800 py-5 sm:py-8 lg:py-10">
      <div className="container mx-auto px-4 sm:px-6 lg:px-8 grid grid-cols-1 sm:grid-cols-2 gap-8">
        <div>
          <h3 className="text-xl font-semibold text-white mb-4">
            {t("ContactUs")}
          </h3>
          <p className="text-gray-300 mb-2">Email: support@vanisarees.in</p>
          <p className="text-gray-300">Phone: +91-8374237713</p>
        </div>
        <div>
          <h3 className="text-xl font-semibold text-white mb-4">
            {t("BussinessAddress")}
          </h3>
          <p className="text-gray-300 mb-2">
            Hno: 283/1, Haneef Colony, Marthanda Nagar, New Hafeezpet,
            Hyderabad, K.V Rangareddy, Telangana.
          </p>
          <p className="text-gray-300">India, 500049</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
