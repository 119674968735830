/* eslint-disable react-refresh/only-export-components */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import { createContext, useContext, useEffect, useState } from "react";
import { jwtDecode } from "jwt-decode";
import Loading from "../Components/UtilsComponent/Loading";
import {
  errorNotification,
  successNotification,
} from "../Notification/Notification";
import { API_ENDPOINT } from "../constants/Api";

enum SendOTPStatus {
  SENT,
  VERIFIED,
  ERROR,
}

const userAuthContext = createContext<any>(null);

export function UserAuthContextProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [authTokens, setAuthTokens] = useState(
    localStorage.getItem("authTokens")
      ? JSON.parse(localStorage.getItem("authTokens") as string)
      : null
  );
  const [user, setUser] = useState(() => {
    const storedTokens = localStorage.getItem("authTokens");
    if (storedTokens && JSON.parse(storedTokens).access) {
      return jwtDecode(JSON.parse(storedTokens).access);
    }
    setAuthTokens(null);
    return null;
  });
  const [isLoading, setIsLoading] = useState(true);

  const signUp = async (
    userName: string,
    email: string,
    password: string,
    password2: string,
    recaptchaValue = null
  ) => {
    const response = await fetch(`${API_ENDPOINT}/register/`, {
      method: "POST",
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: email,
        password: password,
        password2: password2,
        name: userName,
        recaptcha: recaptchaValue,
      }),
    });

    if (response.status === 201) {
      const data = await response.json();
      setAuthTokens(data.token);
      setUser(jwtDecode(data.token.access));
      localStorage.setItem("authTokens", JSON.stringify(data.token));
      return {
        status: response.status,
      };
    } else if (response.status === 429) {
      errorNotification("Too many requests, please try again later");
    } else {
      const data = await response.json();
      const errorMsg = Object.values(data.errors);
      return {
        status: response.status,
        errorMsg: errorMsg,
      };
    }
  };

  const logIn = async (
    email: string,
    password: string,
    recaptchaValue = null
  ) => {
    const response = await fetch(`${API_ENDPOINT}/token/`, {
      method: "POST",
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: email,
        password: password,
        recaptcha: recaptchaValue,
      }),
    });

    if (response.status === 200) {
      const data = await response.json();
      setAuthTokens(data);
      setUser(jwtDecode(data.access));
      localStorage.setItem("authTokens", JSON.stringify(data));
    } else if (response.status === 429) {
      errorNotification("Too many requests, please try again later");
    }
    return response.status;
  };

  function logout() {
    localStorage.removeItem("authTokens");
    setAuthTokens(null);
    setUser(null);
    return true;
  }

  const sendResetEmail = async (email: string) => {
    const response = await fetch(`${API_ENDPOINT}/send-reset-password-email/`, {
      method: "POST",
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email: email }),
    });

    if (response.status === 200) {
      const data = await response.json();
      successNotification(data.message);
      return true;
    } else if (response.status === 401) {
      logout();
      errorNotification("You are not logged in");
      return false;
    } else if (response.status === 429) {
      errorNotification("Too many requests, please try again later");
    } else {
      const data = await response.json();
      return data;
    }
  };

  const ResetEmailPassword = async (
    password: string,
    password2: string,
    uid: string,
    token: string
  ) => {
    const response = await fetch(
      `${API_ENDPOINT}/reset-password/${uid}/${token}/`,
      {
        method: "POST",
        headers: {
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          password: password,
          password2: password2,
        }),
      }
    );

    if (response.status === 200) {
      const data = await response.json();
      successNotification(data.message);
      return true;
    } else if (response.status === 401) {
      logout();
      errorNotification("You are not logged in");
      return false;
    } else if (response.status === 429) {
      errorNotification("Too many requests, please try again later");
    } else {
      const data = await response.json();
      return data;
    }
  };

  const ChangePassword = async (password: string, password2: string) => {
    const response = await fetch(`${API_ENDPOINT}/changepassword/`, {
      method: "PUT",
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authTokens.access}`,
      },
      body: JSON.stringify({
        password: password,
        password2: password2,
      }),
    });

    if (response.status === 200) {
      const data = await response.json();
      successNotification(data.message);
      return true;
    } else if (response.status === 401) {
      logout();
      errorNotification("You are not logged in");
      return false;
    } else if (response.status === 429) {
      errorNotification("Too many requests, please try again later");
    } else {
      const data = await response.json();
      return data;
    }
  };

  const SendOTPVerificationCode = async (phone_number: string) => {
    const response = await fetch(`${API_ENDPOINT}/sendOTPCode/`, {
      method: "POST",
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authTokens.access}`,
      },
      body: JSON.stringify({
        phone_number: phone_number,
      }),
    });

    const data = await response.json();
    if (response.status === 201) {
      successNotification(data.message);
      return {
        status: SendOTPStatus.SENT,
        msg: data.message,
      };
    } else if (response.status === 200) {
      successNotification(data.message);
      return {
        status: SendOTPStatus.VERIFIED,
        msg: data.message,
      };
    } else if (response.status === 401) {
      logout();
      errorNotification("You are not logged in");
      return {
        status: SendOTPStatus.ERROR,
        error: "You are not logged in",
      };
    } else if (response.status === 429) {
      errorNotification("Too many requests, please try again later");
    }
    return {
      status: SendOTPStatus.ERROR,
      error: data.errors,
    };
  };

  const VerifyOTPVerificationCode = async (
    phone_number: string,
    otp: string
  ) => {
    const response = await fetch(`${API_ENDPOINT}/verify_otp/`, {
      method: "POST",
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authTokens.access}`,
      },
      body: JSON.stringify({
        phone_number: phone_number,
        otp: otp,
      }),
    });

    if (response.status === 200) {
      return true;
    } else if (response.status === 401) {
      logout();
      errorNotification("You are not logged in");
      return false;
    } else if (response.status === 429) {
      errorNotification("Too many requests, please try again later");
    }
    return false;
  };

  const ChangeProfile = async (
    email?: string,
    name?: string,
    phone_number?: string,
    otp?: string
  ) => {
    const response = await fetch(`${API_ENDPOINT}/changeprofile/`, {
      method: "PUT",
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authTokens.access}`,
      },
      body: JSON.stringify({
        email,
        name,
        phone_number,
        OTP: otp,
      }),
    });

    if (response.status === 200) {
      const data = await response.json();
      successNotification(data.message);
      return {
        status: true,
        msg: data.message,
        updatedFields: data.updatedFields,
      };
    } else if (response.status === 401) {
      logout();
      errorNotification("You are not logged in");
      return false;
    } else if (response.status === 429) {
      errorNotification("Too many requests, please try again later");
    } else {
      const data = await response.json();
      return {
        status: false,
        error: data.errors,
      };
    }
  };

  const SendEmailVerification = async () => {
    const response = await fetch(
      `${API_ENDPOINT}/send-user-email-verification/`,
      {
        method: "POST",
        headers: {
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
          Authorization: `Bearer ${authTokens.access}`,
        },
      }
    );

    if (response.status === 200) {
      const data = await response.json();
      successNotification(data.message);
      return true;
    } else if (response.status === 401) {
      logout();
      errorNotification("You are not logged in");
      return false;
    } else if (response.status === 429) {
      errorNotification("Too many requests, please try again later");
      return true;
    } else {
      const data = await response.json();
      return data;
    }
  };

  const VerifyEmailVerification = async (uid: string, token: string) => {
    const response = await fetch(
      `${API_ENDPOINT}/verify-user-email/${uid}/${token}/`,
      {
        method: "POST",
        headers: {
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
          Authorization: `Bearer ${authTokens.access}`,
        },
      }
    );

    if (response.status === 200) {
      const data = await response.json();
      successNotification(data.message);
      return true;
    } else if (response.status === 401) {
      logout();
      errorNotification("You are not logged in");
      return false;
    } else if (response.status === 429) {
      errorNotification("Too many requests, please try again later");
    } else {
      const data = await response.json();
      return data;
    }
  };

  const ChangeEmailVerification = async (
    uid: string,
    token: string,
    encodedEmail: string
  ) => {
    const response = await fetch(
      `${API_ENDPOINT}/change-email/${uid}/${token}/${encodedEmail}/`,
      {
        method: "POST",
        headers: {
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
        },
      }
    );

    if (response.status === 200) {
      const data = await response.json();
      successNotification(data.message);
      return true;
    } else if (response.status === 401) {
      logout();
      errorNotification("You are not logged in");
      return false;
    } else if (response.status === 429) {
      errorNotification("Too many requests, please try again later");
    } else {
      const data = await response.json();
      return data;
    }
  };

  async function getProfile() {
    const response = await fetch(`${API_ENDPOINT}/profile/`, {
      method: "GET",
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authTokens.access}`,
      },
    });
    if (response.status === 200) {
      const data = await response.json();
      return data;
    } else if (response.status === 401) {
      logout();
      errorNotification("You are not logged in");
      return false;
    } else if (response.status === 429) {
      errorNotification("Too many requests, please try again later");
    } else {
      errorNotification("Something went wrong");
      return false;
    }
  }

  const refreshToken = async () => {
    // const response = await fetch(`${API_ENDPOINT}/token/refresh/`, {
    //   method: "POST",
    //   headers: {
    //     Accept: "application/json, text/plain, */*",
    //     "Content-Type": "application/json",
    //   },
    //   body: JSON.stringify({ refresh: authTokens?.refresh }),
    // });

    // if (response.status === 200) {
    //   const data = await response.json();
    //   setAuthTokens(data);
    //   setUser(jwtDecode(data.access));
    //   localStorage.setItem("authTokens", JSON.stringify(data));
    // } else if (response.status === 401) {
    //   logout();
    // } else if (response.status === 429) {
    //   errorNotification("Too many requests, please try again later");
    // }

    if (isLoading) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (isLoading) {
      refreshToken();
    }

    const interval: any = setInterval(
      () => {
        if (authTokens) {
          refreshToken();
        }
      },
      1000 * 60 * 4
    );

    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authTokens]);

  return (
    <userAuthContext.Provider
      value={{
        user,
        authTokens,
        SendOTPStatus,
        signUp,
        logIn,
        logout,
        getProfile,
        sendResetEmail,
        ResetEmailPassword,
        ChangePassword,
        SendOTPVerificationCode,
        VerifyOTPVerificationCode,
        ChangeProfile,
        ChangeEmailVerification,
        SendEmailVerification,
        VerifyEmailVerification,
      }}
    >
      {isLoading ? <Loading /> : children}
    </userAuthContext.Provider>
  );
}

export function useUserAuth() {
  return useContext(userAuthContext);
}
