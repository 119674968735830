// import {
//   createBrowserRouter,
//   RouterProvider,
//   Navigate,
// } from "react-router-dom";
// import React, { Suspense } from "react";
// import { UserAuthContextProvider } from "../Context/UserAuthContext";
// // import { Toaster } from "react-hot-toast";
// import { ProductsContextProvider } from "../Context/ProductsContext";
// import ProtectedRoute from "../Components/UtilsComponent/ProtectedRoute";
// import ProfileLayout from "../Components/LayoutComponent/ProfileLayout";
// import Layout from "../Components/LayoutComponent/Layout";
// import CheckLogin from "../Components/UtilsComponent/CheckLogin";
// import ErrorBoundary from "../Components/UtilsComponent/ErrorBoundary";
// import Loading from "../Components/UtilsComponent/Loading";
// const Login = React.lazy(
//   () => import("../Components/LoginSignupComponent/Login")
// );
// const Signup = React.lazy(
//   () => import("../Components/LoginSignupComponent/Signup")
// );
// const Home = React.lazy(() => import("../Components/Home"));
// const ProductsDetailspage = React.lazy(
//   () => import("../Components/ProductsComponent/ProductsDetailsPage")
// );
// const CartCheckoutPage = React.lazy(
//   () => import("../Components/CheckoutComponent/CheckoutPage")
// );
// const OrderList = React.lazy(
//   () => import("../Components/OrdersComponent/OrdersList")
// );
// const OrderDetails = React.lazy(
//   () => import("../Components/OrdersComponent/OrdersDetails")
// );
// const PasswordResetPage = React.lazy(
//   () => import("../Components/ProfileComponent/PasswordResetWithLink")
// );
// const EmailVerificationDialog = React.lazy(
//   () => import("../Components/ProfileComponent/EmailVerificationDialog")
// );
// const ChangePassword = React.lazy(
//   () => import("../Components/ProfileComponent/ChangePassword")
// );
// const EmailChangeDialog = React.lazy(
//   () => import("../Components/ProfileComponent/EmailChangeDialog")
// );
// const NotFoundPage = React.lazy(
//   () => import("../Components/UtilsComponent/NotFoundPage")
// );

// const router = createBrowserRouter([
//   { path: "/", element: <Navigate to="/Home" replace /> },
//   {
//     path: "login",
//     element: (
//       <CheckLogin>
//         <ErrorBoundary>
//           <Suspense fallback={<Loading />}>
//             <Login />
//           </Suspense>
//         </ErrorBoundary>
//       </CheckLogin>
//     ),
//   },
//   {
//     path: "signup",
//     element: (
//       <CheckLogin>
//         <ErrorBoundary>
//           <Suspense fallback={<Loading />}>
//             <Signup />
//           </Suspense>
//         </ErrorBoundary>
//       </CheckLogin>
//     ),
//   },

//   {
//     path: "/Home",
//     element: <Layout />,
//     children: [
//       {
//         path: "",
//         element: (
//           <ErrorBoundary>
//             <Suspense fallback={<Loading />}>
//               <Home />
//             </Suspense>
//           </ErrorBoundary>
//         ),
//       },
//     ],
//   },
//   {
//     path: "product/:id",
//     element: <Layout />,
//     children: [
//       {
//         path: "",
//         element: (
//           <ErrorBoundary>
//             <Suspense fallback={<Loading />}>
//               <ProductsDetailspage />
//             </Suspense>
//           </ErrorBoundary>
//         ),
//       },
//       {
//         path: "buy",
//         element: (
//           <ErrorBoundary>
//             <Suspense fallback={<Loading />}>
//               <ProtectedRoute>
//                 <CartCheckoutPage />
//               </ProtectedRoute>
//             </Suspense>
//           </ErrorBoundary>
//         ),
//       },
//     ],
//   },
//   {
//     path: "cart/checkout",
//     element: <Layout />,
//     children: [
//       {
//         path: "",
//         element: (
//           <ErrorBoundary>
//             <Suspense fallback={<Loading />}>
//               <ProtectedRoute>
//                 <CartCheckoutPage />
//               </ProtectedRoute>
//             </Suspense>
//           </ErrorBoundary>
//         ),
//       },
//     ],
//   },
//   {
//     path: "orders",
//     element: <Layout />,
//     children: [
//       {
//         path: "",
//         element: (
//           <ErrorBoundary>
//             <Suspense fallback={<Loading />}>
//               <ProtectedRoute>
//                 <OrderList />
//               </ProtectedRoute>
//             </Suspense>
//           </ErrorBoundary>
//         ),
//       },
//     ],
//   },
//   {
//     path: "order/:id",
//     element: <Layout />,
//     children: [
//       {
//         path: "",
//         element: (
//           <ErrorBoundary>
//             <Suspense fallback={<Loading />}>
//               <ProtectedRoute>
//                 <OrderDetails />
//               </ProtectedRoute>
//             </Suspense>
//           </ErrorBoundary>
//         ),
//       },
//     ],
//   },
//   {
//     path: "password-reset-link/:uid/:token",
//     element: <Layout />,
//     children: [
//       {
//         path: "",
//         element: (
//           <ErrorBoundary>
//             <Suspense fallback={<Loading />}>
//               <PasswordResetPage />
//             </Suspense>
//           </ErrorBoundary>
//         ),
//       },
//     ],
//   },
//   {
//     path: "changepassword",
//     element: <Layout />,
//     children: [
//       {
//         path: "",
//         element: (
//           <ErrorBoundary>
//             <Suspense fallback={<Loading />}>
//               <ProtectedRoute>
//                 <ChangePassword />
//               </ProtectedRoute>
//             </Suspense>
//           </ErrorBoundary>
//         ),
//       },
//     ],
//   },
//   {
//     path: "profile",
//     element: (
//       <ErrorBoundary>
//         <Suspense fallback={<Loading />}>
//           <ProtectedRoute>
//             <ProfileLayout />
//           </ProtectedRoute>
//         </Suspense>
//       </ErrorBoundary>
//     ),
//     children: [
//       {
//         path: "email-verify/:uid/:token",
//         element: (
//           <ErrorBoundary>
//             <Suspense fallback={<Loading />}>
//               <ProtectedRoute>
//                 <EmailVerificationDialog />
//               </ProtectedRoute>
//             </Suspense>
//           </ErrorBoundary>
//         ),
//       },
//     ],
//   },
//   {
//     path: "email-change/:uid/:token/:encodedEmail",
//     element: <Layout />,
//     children: [
//       {
//         path: "",
//         element: (
//           <ErrorBoundary>
//             <Suspense fallback={<Loading />}>
//               <EmailChangeDialog />
//             </Suspense>
//           </ErrorBoundary>
//         ),
//       },
//     ],
//   },
//   {
//     path: "*",
//     element: <NotFoundPage />,
//   },
// ]);

// const AppRouter = () => {
//   return (
//     <>
//       <UserAuthContextProvider>
//         <ProductsContextProvider>
//           <RouterProvider router={router} />
//         </ProductsContextProvider>
//       </UserAuthContextProvider>
//     </>
//   );
// };

// export default AppRouter;

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import React, { ReactNode, Suspense } from "react";
import { UserAuthContextProvider } from "../Context/UserAuthContext";
import { ProductsContextProvider } from "../Context/ProductsContext";
import ProtectedRoute from "../Components/UtilsComponent/ProtectedRoute";
import ProfileLayout from "../Components/LayoutComponent/ProfileLayout";
import Layout from "../Components/LayoutComponent/Layout";
import CheckLogin from "../Components/UtilsComponent/CheckLogin";
import ErrorBoundary from "../Components/UtilsComponent/ErrorBoundary";
import Loading from "../Components/UtilsComponent/Loading";

interface LayoutWithSuspenseProps {
  children: ReactNode;
}

const Login = React.lazy(
  () => import("../Components/LoginSignupComponent/Login")
);
const Signup = React.lazy(
  () => import("../Components/LoginSignupComponent/Signup")
);
const Home = React.lazy(() => import("../Components/Home"));
const ProductsDetailspage = React.lazy(
  () => import("../Components/ProductsComponent/ProductsDetailsPage")
);
const CartCheckoutPage = React.lazy(
  () => import("../Components/CheckoutComponent/CheckoutPage")
);
const OrderList = React.lazy(
  () => import("../Components/OrdersComponent/OrdersList")
);
const OrderDetails = React.lazy(
  () => import("../Components/OrdersComponent/OrdersDetails")
);
const PasswordResetPage = React.lazy(
  () => import("../Components/ProfileComponent/PasswordResetWithLink")
);
const EmailVerificationDialog = React.lazy(
  () => import("../Components/ProfileComponent/EmailVerificationDialog")
);
const ChangePassword = React.lazy(
  () => import("../Components/ProfileComponent/ChangePassword")
);
const EmailChangeDialog = React.lazy(
  () => import("../Components/ProfileComponent/EmailChangeDialog")
);
const NotFoundPage = React.lazy(
  () => import("../Components/UtilsComponent/NotFoundPage")
);

const AppRouter = () => {
  return (
    <UserAuthContextProvider>
      <ProductsContextProvider>
        <Router>
          <Routes>
            <Route path="/" element={<Navigate to="/Home" replace />} />
            <Route path="login" element={<LoginWithCheck />} />
            <Route path="signup" element={<SignupWithCheck />} />
            <Route
              path="/Home"
              element={<LayoutWithSuspense children={<Home />} />}
            />
            <Route
              path="product/:id"
              element={
                <LayoutWithSuspense children={<ProductsDetailspage />} />
              }
            >
              <Route
                path="buy"
                element={
                  <LayoutWithSuspense
                    children={
                      <ProtectedRoute children={<CartCheckoutPage />} />
                    }
                  />
                }
              />
            </Route>
            <Route
              path="cart/checkout"
              element={
                <LayoutWithSuspense
                  children={<ProtectedRoute children={<CartCheckoutPage />} />}
                />
              }
            />
            <Route
              path="orders"
              element={
                <LayoutWithSuspense
                  children={<ProtectedRoute children={<OrderList />} />}
                />
              }
            />
            <Route
              path="order/:id"
              element={
                <LayoutWithSuspense
                  children={<ProtectedRoute children={<OrderDetails />} />}
                />
              }
            />
            <Route
              path="password-reset-link/:uid/:token"
              element={<LayoutWithSuspense children={<PasswordResetPage />} />}
            />
            <Route
              path="changepassword"
              element={
                <LayoutWithSuspense
                  children={<ProtectedRoute children={<ChangePassword />} />}
                />
              }
            />
            <Route
              path="profile"
              element={
                <LayoutWithSuspense
                  children={<ProtectedRoute children={<ProfileLayout />} />}
                />
              }
            >
              <Route
                path="email-verify/:uid/:token"
                element={
                  <LayoutWithSuspense
                    children={
                      <ProtectedRoute children={<EmailVerificationDialog />} />
                    }
                  />
                }
              />
            </Route>
            <Route
              path="email-change/:uid/:token/:encodedEmail"
              element={<LayoutWithSuspense children={<EmailChangeDialog />} />}
            />
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </Router>
      </ProductsContextProvider>
    </UserAuthContextProvider>
  );
};

const LayoutWithSuspense: React.FC<LayoutWithSuspenseProps> = ({
  children,
}) => (
  <Layout>
    <ErrorBoundary>
      <Suspense fallback={<Loading />}>{children}</Suspense>
    </ErrorBoundary>
  </Layout>
);

const LoginWithCheck = () => (
  <CheckLogin>
    <ErrorBoundary>
      <Suspense fallback={<Loading />}>
        <Login />
      </Suspense>
    </ErrorBoundary>
  </CheckLogin>
);

const SignupWithCheck = () => (
  <CheckLogin>
    <ErrorBoundary>
      <Suspense fallback={<Loading />}>
        <Signup />
      </Suspense>
    </ErrorBoundary>
  </CheckLogin>
);

export default AppRouter;
