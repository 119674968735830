export interface IState {
  name: string;
  isoCode: string;
  countryCode: string;
  latitude?: string | null;
  longitude?: string | null;
  getAllStates?(): IState[];
  searchStates?(state: string): IState[];
}

export interface ICity {
  name: string;
  countryCode: string;
  stateCode: string;
  latitude?: string | null;
  longitude?: string | null;
  getAllCities?(): ICity[];
  getCitiesOfState?(state: string): ICity[];
}

// To format the INR currency
const formatCurrency = (amount: number) => {
  return amount.toLocaleString("en-IN", {
    style: "currency",
    currency: "INR",
  });
};

// To round up the number
const roundUpNumber = (num: number) => {
  return Math.ceil(num);
};

import statesData from "../../RawLocationData/states.json";
import citiesData from "../../RawLocationData/cities.json";

// Utility function to search for states based on user input
const getAllStates = () => {
  return statesData.states;
};

const getCitiesOfState = (stateCode: string) => {
  return citiesData.cities.filter((city) => city.stateCode === stateCode);
};

export { roundUpNumber, formatCurrency, getAllStates, getCitiesOfState };
