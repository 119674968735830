import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import enJson from "../locale/en_US.json";
import deJson from "../locale/de.json";
import esJson from "../locale/es.json";
import hiJson from "../locale/hi_IN.json";
import teJson from "../locale/te_IN.json";
import mlJson from "../locale/ml_IN.json";
import taJson from "../locale/ta_IN.json";
i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources: {
      en_US: { ...enJson },
      de: { ...deJson },
      es: { ...esJson },
      hi_IN: { ...hiJson },
      te_IN: { ...teJson },
      ml_IN: { ...mlJson },
      ta_IN: { ...taJson },
    },
    debug: false,
    fallbackLng: "en_US",
  });
