// import { Outlet } from "react-router-dom";
// import Appbar from "./Appbar";
// import { useState } from "react";
// import Cart from "../CartComponent/Cart";
// import Footer from "./FooterSection";

// const Layout = () => {
//   const [isCartOpen, setIsCartOpen] = useState(false);

//   return (
//     <>
//       <Appbar isCartOpen={isCartOpen} setIsCartOpen={setIsCartOpen} />
//       <main>
//         <Cart isCartOpen={isCartOpen} setIsCartOpen={setIsCartOpen} />
//         <Outlet />
//       </main>
//       <Footer />
//     </>
//   );
// };
// export default Layout;

import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import Appbar from "./Appbar";
import Cart from "../CartComponent/Cart";
import Footer from "./FooterSection";

interface LayoutProps {
  children: React.ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
  const [isCartOpen, setIsCartOpen] = useState(false);

  // const handleCartToggle = () => {
  //   setIsCartOpen((prevState) => !prevState);
  // };

  return (
    <>
      <Appbar isCartOpen={isCartOpen} setIsCartOpen={setIsCartOpen} />
      <main>
        <Cart isCartOpen={isCartOpen} setIsCartOpen={setIsCartOpen} />
        <Outlet />
        {children}
      </main>
      <Footer />
    </>
  );
};

export default Layout;
