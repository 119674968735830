import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useUserAuth } from "../../Context/UserAuthContext";

export default function ProtectedRoute({
  children,
}: {
  children: React.ReactNode;
}) {
  const { pathname } = useLocation();

  const { user } = useUserAuth();

  if (!user) {
    return <>{children}</>;
  }
  return <Navigate to="/" replace state={{ referrer: pathname }} />;
}
