/* eslint-disable react-refresh/only-export-components */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { createContext, useContext, useEffect, useState } from "react";
import { useUserAuth } from "./UserAuthContext";
import {
  successNotification,
  errorNotification,
} from "../Notification/Notification";
import { API_ENDPOINT } from "../constants/Api";

const initialState = {
  cartItems: [],
  cartPrice: 0,
  cartGstPrice: 0,
  cartCodPrice: 0,
};
const productsContext = createContext<any>(null);

// interface orderItem {
//     order_id: number;
//     channel_order_id: string;
//     customer_name: string;
//     customer_email: string;
//     customer_phone: string;
//     customer_address: string;
//     customer_city: string;
//     customer_state: string;
//     customer_pincode: string;
//     customer_country: string;
//     payment_method: string;
//     total: string;
//     status: string;
//     product_details: [
//         {
//             channel_order_product_id: string;
//             product_id: number;
//             name: string;
//             channel_sku: string;
//             quantity: 1;
//             status: string;
//             mrp: number;
//             image_one: string | null;
//             image_two: string | null;
//             image_three: string | null;
//             image_four: string | null;
//             image_five: string | null;
//         }
//     ];
//     created_at: string;
// }

// interface orderParams {
//     count: number;
//     previous: string;
//     next: string;
//     results: orderItem[];
// }

const initialOrderState = {
  count: 0,
  previous: "",
  next: "",
  results: [],
};

export function ProductsContextProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [products, setProducts] = useState({ results: [] });
  const [savedProducts, setSavedProducts] = useState({});
  const [cart, setCart] = useState([]);
  const [cartItems, setCartItems] = useState(initialState);
  const [orderItems, setOrderItems] = useState(initialOrderState);
  const [isLoading, setLoading] = useState(false);
  const [productCategories, setProductCategories] = useState([]);
  const { user, authTokens, logout } = useUserAuth();

  // Cart Reducer

  interface cartReducerAction {
    type: "ADD_TO_CART" | "UPDATE_CART_ITEM" | "REMOVE_FROM_CART";
    payload: any;
  }

  const cartReducer = (state: any, action: cartReducerAction) => {
    switch (action.type) {
      case "ADD_TO_CART":
        return [{ ...action.payload }, ...state];
      case "UPDATE_CART_ITEM":
        return state.map((item: any) =>
          item.id === action.payload.id
            ? { ...item, quantity: action.payload.quantity }
            : item
        );
      case "REMOVE_FROM_CART":
        return state.filter((item: any) => item.id !== action.payload);
      default:
        return state;
    }
  };

  const cartDispatch = (action: cartReducerAction) => {
    setCart((prev) => cartReducer(prev, action));
  };

  const getProducts = async (
    page = 1,
    screenType = null,
    search: string,
    minPrice: number,
    maxPrice: number,
    cat: string
  ) => {
    setLoading(true);
    const isSaved = Object.entries(savedProducts).find(
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      ([key]) => key === page.toString()
    );
    if (isSaved && !search) {
      setProducts(isSaved[1] as any);
      setLoading(false);
    } else {
      const response = await fetch(
        `${API_ENDPOINT}/products/?page=${page}${
          search ? `&search=${search}` : ""
        }${screenType !== null ? `&type=${screenType}` : ""}
        ${minPrice ? `&min=${minPrice}` : ""}${maxPrice ? `&max=${maxPrice}` : ""}
        ${cat ? `&cat=${cat}` : ""}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
            Authorization:
              authTokens != null ? `Bearer ${authTokens.access}` : "",
          },
        }
      );
      setLoading(false);
      if (response.status === 200) {
        const data = await response.json();
        setProducts(data);
        setSavedProducts({
          ...savedProducts,
          [`${page}`]: data,
        });
      } else if (response.status === 401) {
        logout();
        errorNotification("You are not logged in");
      } else if (response.status === 429) {
        errorNotification("Too many requests, please try again later");
      } else {
        errorNotification("Something went wrong");
        setProducts({ results: [] });
      }
    }
  };

  const getProduct = async (id: number) => {
    setLoading(true);
    const response = await fetch(`${API_ENDPOINT}/get_product/${id}/`, {
      method: "GET",
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
        Authorization: authTokens != null ? `Bearer ${authTokens.access}` : "",
      },
    });
    setLoading(false);
    if (response.status === 200) {
      const data = await response.json();
      return data;
    } else if (response.status === 401) {
      logout();
      errorNotification("You are not logged in");
      return false;
    } else if (response.status === 429) {
      errorNotification("Too many requests, please try again later");
      return false;
    } else {
      errorNotification("Something went wrong");
      return false;
    }
  };

  const getIp = async () => {
    setLoading(true);
    const response = await fetch(`${API_ENDPOINT}/ipaddress/`, {
      method: "GET",
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
      },
    });
    setLoading(false);
    if (response.status === 200) {
      const data = await response.json();
      return data.ip;
    } else if (response.status === 401) {
      logout();
      errorNotification("You are not logged in");
      return false;
    } else if (response.status === 429) {
      errorNotification("Too many requests, please try again later");
      return false;
    } else {
      errorNotification("Something went wrong");
      return false;
    }
  };

  const getProductCategories = async () => {
    setLoading(true);
    const response = await fetch(`${API_ENDPOINT}/get_products_category/`, {
      method: "GET",
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
      },
    });
    setLoading(false);
    if (response.status === 200) {
      const data = await response.json();
      setProductCategories(data);
    } else if (response.status === 401) {
      logout();
      errorNotification("You are not logged in");
      return false;
    } else if (response.status === 429) {
      errorNotification("Too many requests, please try again later");
      return false;
    } else {
      errorNotification("Something went wrong");
      return false;
    }
  };

  const getCart = async () => {
    setLoading(true);
    const response = await fetch(`${API_ENDPOINT}/cart/`, {
      method: "GET",
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authTokens.access}`,
      },
    });
    setLoading(false);
    if (response.status === 200) {
      const data = await response.json();
      setCart(data);
    } else if (response.status === 404) {
      setCart([]);
    } else if (response.status === 401) {
      logout();
      errorNotification("You are not logged in");
    } else if (response.status === 429) {
      errorNotification("Too many requests, please try again later");
    } else {
      errorNotification("Something went wrong");
    }
  };

  const addToCart = async (id: number, cartQuantity: number) => {
    setLoading(true);
    const response = await fetch(`${API_ENDPOINT}/cart/`, {
      method: "POST",
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authTokens.access}`,
      },
      body: JSON.stringify({
        product: id,
        quantity: cartQuantity,
      }),
    });
    setLoading(false);
    if (response.status === 200) {
      const res = await response.json();
      cartDispatch({ type: "UPDATE_CART_ITEM", payload: res.cart });
      successNotification(res.message);
    } else if (response.status === 201) {
      const res = await response.json();
      cartDispatch({ type: "ADD_TO_CART", payload: res.cart });
      successNotification(res.message);
    } else if (response.status === 401) {
      logout();
      errorNotification("You are not logged in");
    } else if (response.status === 429) {
      errorNotification("Too many requests, please try again later");
    } else {
      errorNotification("Something went wrong");
    }
  };

  const removeItemFromCart = async (id: number) => {
    setLoading(true);
    const response = await fetch(`${API_ENDPOINT}/delete_cart/${id}/`, {
      method: "DELETE",
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authTokens.access}`,
      },
    });
    setLoading(false);
    if (response.status === 200) {
      const data = await response.json();
      cartDispatch({ type: "REMOVE_FROM_CART", payload: data.cartId });
      successNotification(data.message);
    } else if (response.status === 401) {
      logout();
      errorNotification("You are not logged in");
    } else if (response.status === 429) {
      errorNotification("Too many requests, please try again later");
    } else {
      errorNotification("Something went wrong");
    }
  };

  const getOrderItems = async (page = 1) => {
    setLoading(true);
    const response = await fetch(`${API_ENDPOINT}/get_orders/?page=${page}`, {
      method: "GET",
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authTokens.access}`,
      },
    });
    setLoading(false);
    if (response.status === 200) {
      const data = await response.json();
      setOrderItems(data);
      return data;
    } else if (response.status === 401) {
      logout();
      errorNotification("You are not logged in");
      return false;
    } else if (response.status === 429) {
      errorNotification("Too many requests, please try again later");
      return false;
    } else {
      errorNotification("Something went wrong!");
      return false;
    }
  };

  const getSpecificOrderItem = async (id: number) => {
    setLoading(true);
    const response = await fetch(`${API_ENDPOINT}/get_order/${id}`, {
      method: "GET",
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authTokens.access}`,
      },
    });
    setLoading(false);
    if (response.status === 200) {
      const data = await response.json();
      return data.Product;
    } else if (response.status === 401) {
      logout();
      errorNotification("You are not logged in");
      return false;
    } else if (response.status === 429) {
      errorNotification("Too many requests, please try again later");
      return false;
    } else {
      errorNotification("Something went wrong!");
      return false;
    }
  };

  const getShipmentDetails = async (trackId: number) => {
    setLoading(true);
    const response = await fetch(`${API_ENDPOINT}/get_shipment/${trackId}`, {
      method: "GET",
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authTokens.access}`,
      },
    });
    setLoading(false);
    if (response.status === 200) {
      const data = await response.json();
      return data.Shipment;
    } else if (response.status === 401) {
      logout();
      errorNotification("You are not logged in");
      return false;
    } else if (response.status === 429) {
      errorNotification("Too many requests, please try again later");
      return false;
    } else {
      errorNotification("Something went wrong!");
      return false;
    }
  };

  const cancelOrder = async (id: number, cancel_reason: string) => {
    setLoading(true);
    const response = await fetch(`${API_ENDPOINT}/cancel_order/${id}/`, {
      method: "POST",
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authTokens.access}`,
      },
      body: JSON.stringify({
        cancel_reason: cancel_reason,
      }),
    });
    setLoading(false);
    if (response.status === 200) {
      const data = await response.json();
      successNotification(data.message);
      return true;
    } else if (response.status === 401) {
      logout();
      errorNotification("You are not logged in");
      return false;
    } else if (response.status === 429) {
      errorNotification("Too many requests, please try again later");
      return false;
    } else {
      const data = await response.json();
      errorNotification(data.error);
      return false;
    }
  };

  const updateDeliveryAddress = async (id: number, params: any) => {
    const response = await fetch(
      `${API_ENDPOINT}/update_order_delivery_location/${id}/`,
      {
        method: "POST",
        headers: {
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
          Authorization: `Bearer ${authTokens.access}`,
        },
        body: JSON.stringify(params),
      }
    );
    if (response.status === 200) {
      const data = await response.json();
      successNotification(data.message);
      return {
        status: true,
        message: data.message,
      };
    } else if (response.status === 422) {
      const data = await response.json();
      errorNotification(data.error);
      return {
        status: false,
        errors: data.details.errors,
      };
    } else if (response.status === 401) {
      logout();
      errorNotification("You are not logged in");
      return {
        status: false,
        errors: ["You are not logged in"],
      };
    } else if (response.status === 429) {
      errorNotification("Too many requests, please try again later");
      return false;
    } else {
      const data = await response.json();
      return {
        status: false,
        errors: data.errors,
      };
    }
  };

  const getTrackingLog = async (id: number) => {
    setLoading(true);
    const response = await fetch(`${API_ENDPOINT}/trackOrder/${id}`, {
      method: "GET",
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authTokens.access}`,
      },
    });
    setLoading(false);
    if (response.status === 200) {
      const data = await response.json();
      return data;
    } else if (response.status === 401) {
      logout();
      errorNotification("You are not logged in");
      return false;
    } else if (response.status === 429) {
      errorNotification("Too many requests, please try again later");
      return false;
    } else {
      const data = await response.json();
      errorNotification(data.error);
      return false;
    }
  };

  interface cartItemsReducerAction {
    type: "ADD_TO_CART" | "UPDATE_CART" | "REMOVE_FROM_CART";
    payload: any;
  }

  const cartItemsReducer = (state: any, action: cartItemsReducerAction) => {
    const cart = state.cartItems.find(
      (item: any) => item.id === action.payload.id
    );
    let cartPrice;
    let cartGstPrice;
    let cartCodPrice;
    switch (action.type) {
      case "ADD_TO_CART":
        return {
          ...state,
          cartItems: [...state.cartItems, action.payload],
          cartPrice:
            state.cartPrice +
            action.payload.quantity *
              parseInt(action.payload.product.price, 10),
          cartGstPrice:
            state.cartGstPrice +
            (action.payload.quantity *
              parseInt(action.payload.product.price) *
              parseInt(action.payload.product.gst_percentage)) /
              100,
          cartCodPrice: state.cartCodPrice + action.payload.quantity * 50,
        };
      case "UPDATE_CART":
        cartPrice =
          state.cartPrice -
          cart.quantity * parseInt(cart.product.price, 10) +
          action.payload.quantity * parseInt(action.payload.product.price, 10);
        cartGstPrice =
          state.cartGstPrice -
          cart.quantity *
            parseInt(cart.product.price, 10) *
            (parseInt(cart.product.gst_percentage) / 100) +
          action.payload.quantity *
            parseInt(action.payload.product.price) *
            (parseInt(action.payload.product.gst_percentage) / 100);
        cartCodPrice =
          state.cartCodPrice -
          cart.quantity * 50 +
          action.payload.quantity * 50;
        if (cart) {
          return {
            ...state,
            cartItems: state.cartItems.map((item: any) =>
              item.id === action.payload.id
                ? { ...item, quantity: action.payload.quantity }
                : item
            ),
            cartPrice: cartPrice,
            cartGstPrice: cartGstPrice,
            cartCodPrice: cartCodPrice,
          };
        }
        return state;
      case "REMOVE_FROM_CART":
        return {
          ...state,
          cartItems: state.cartItems.filter(
            (item: any) => item.id !== action.payload.id
          ),
          cartPrice:
            state.cartPrice -
            action.payload.quantity *
              parseInt(action.payload.product.price, 10),
          cartGstPrice:
            state.cartGstPrice -
            action.payload.quantity *
              parseInt(action.payload.product.price) *
              (parseInt(action.payload.product.gst_percentage) / 100),
          cartCodPrice: state.cartCodPrice - action.payload.quantity * 50,
        };
      default:
        return state;
    }
  };

  const dispatchCartAction = (action: cartItemsReducerAction) => {
    setCartItems((state) => cartItemsReducer(state, action));
  };

  useEffect(() => {
    const getdata = async () => {
      await getProductCategories();
      if (user) {
        await getCart();
      }
    };
    getdata();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (cartItems.cartItems.length === 0) {
      if (cart.length > 0) {
        // Add a check to ensure cart is not empty
        cart.forEach((item: any) => {
          if (
            item.product && // Add a check to ensure item has the product property
            item.product.product_availability_count !== 0 &&
            item.product.product_availability &&
            item.product.product_availability_count >= item.quantity
          ) {
            dispatchCartAction({ type: "ADD_TO_CART", payload: item });
          }
        });
      }
    } else {
      cartItems.cartItems.forEach((item: any) => {
        // Check if the item is already in the cart
        const isItemInCart = cart.find(
          (cartItem: any) => cartItem.id === item.id
        );

        // Dispatch the appropriate action based on the presence of the item
        if (isItemInCart !== undefined) {
          dispatchCartAction({ type: "UPDATE_CART", payload: isItemInCart });
        } else {
          dispatchCartAction({ type: "REMOVE_FROM_CART", payload: item });
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cart]);

  return (
    <productsContext.Provider
      value={{
        products,
        cart,
        isLoading,
        productCategories,
        getProducts,
        getProduct,
        getCart,
        addToCart,
        removeItemFromCart,
        cartItems,
        setCartItems,
        initialState,
        dispatchCartAction,
        getOrderItems,
        getSpecificOrderItem,
        getShipmentDetails,
        cancelOrder,
        updateDeliveryAddress,
        getTrackingLog,
        orderItems,
        getIp,
      }}
    >
      {children}
    </productsContext.Provider>
  );
}

export default function useProductsContext() {
  return useContext(productsContext);
}
