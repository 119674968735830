import HashLoader from "react-spinners/HashLoader";

const override = {
  display: "block",
  margin: "0 auto",
  borderColor: "red",
};

export default function Loading(props: { className?: string }) {
  return (
    <div
      className={`flex h-screen justify-center items-center ${props.className}`}
    >
      <HashLoader
        color={"#4d94ff"}
        loading={true}
        size={150}
        cssOverride={override}
        aria-label="Loading Spinner"
        data-testid="loader"
      />
    </div>
  );
}
