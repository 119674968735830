/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from "react";
import useProductsContext from "../../Context/ProductsContext";
import { useNavigate, Link } from "react-router-dom";
import Loading from "../UtilsComponent/Loading";
import { successNotification } from "../../Notification/Notification";
import { formatCurrency } from "../UtilsComponent/Utilities";
import { useTranslation } from "react-i18next";

interface ICartProps {
  isCartOpen: boolean;
  setIsCartOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function Cart({ isCartOpen, setIsCartOpen }: ICartProps) {
  const { t } = useTranslation();
  const [translate, setTranslate] = useState(false);
  const {
    cart,
    getCart,
    removeItemFromCart,
    isLoading,
    cartItems,
    setCartItems,
    initialState,
    dispatchCartAction,
  } = useProductsContext();

  const navigate = useNavigate();

  const closeCart = () => {
    setTranslate(false);
    setTimeout(() => {
      setIsCartOpen(false);
    }, 400);
  };

  const handleCartSelection = (item: any) => {
    if (cartItems.cartItems.some((cartItem: any) => cartItem.id === item.id)) {
      dispatchCartAction({ type: "REMOVE_FROM_CART", payload: item });
    } else {
      dispatchCartAction({ type: "ADD_TO_CART", payload: item });
    }
  };

  const handleRemoveFromCart = async (id: number) => {
    closeCart();
    await removeItemFromCart(id);
  };

  useEffect(() => {
    const init = async () => {
      if (isCartOpen) {
        setTranslate(true);
      } else {
        setTranslate(false);
      }
    };

    init();
  }, [isCartOpen]);

  if (isCartOpen) {
    return (
      <div className="relative z-30">
        <div className="flex fixed transition-all inset-0 bg-black/75 backdrop-blur-sm opacity-100">
          <div
            className="pointer-events-auto fixed left-0 top-0 h-full p-2 w-1/12 -translate-x-0 opacity-100 lg:w-3/4 md:w-3/5 sm:w-2/4"
            onClick={closeCart}
          ></div>
          <div
            className={`pointer-events-auto fixed right-0 top-0 h-full p-2 transition-all duration-300 ${
              translate ? "translate-x-0" : "translate-x-[200%]"
            }  opacity-100 w-11/12 lg:w-1/4 md:w-2/5 sm:w-2/4 `}
          >
            <div className="flex flex-col h-full bg-white rounded-xl">
              <div className="flex items-center gap-2 p-2 pt-4">
                <button
                  className="flex h-8 w-8 items-center justify-center rounded-lg text-2xl hover:bg-black/20"
                  onClick={closeCart}
                >
                  <svg
                    className="text-gray-700"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="currentColor"
                      d="M17,11H9.41l3.3-3.29a1,1,0,1,0-1.42-1.42l-5,5a1,1,0,0,0-.21.33,1,1,0,0,0,0,.76,1,1,0,0,0,.21.33l5,5a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42L9.41,13H17a1,1,0,0,0,0-2Z"
                    ></path>
                  </svg>
                </button>
                <div className="flex w-full">
                  <h1 className="flex w-full text-xl text-gray-700 font-semibold justify-between">
                    {t("CartItems")}
                    <span className="flex items-center ">
                      <button
                        className="ml-2 p-1 text-sm flex items-center space-x-1 bg-red-400 rounded-md"
                        onClick={() => {
                          setCartItems(initialState);
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          className="h-4 w-4 text-white"
                        >
                          <path
                            fill="#FFFFFF"
                            d="M18.7,7.2c-0.4-0.4-1-0.4-1.4,0l-7.5,7.5l-3.1-3.1c0,0,0,0,0,0c-0.4-0.4-1-0.4-1.4,0c-0.4,0.4-0.4,1,0,1.4l3.8,3.8c0.2,0.2,0.4,0.3,0.7,0.3c0.3,0,0.5-0.1,0.7-0.3l8.2-8.2C19.1,8.2,19.1,7.6,18.7,7.2z"
                          ></path>
                        </svg>
                        <span className="text-white">{t("DeselectAll")}</span>
                      </button>
                      <button
                        className="ml-2"
                        onClick={async () => {
                          successNotification("Refreshing..");
                          await getCart();
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          className="h-5 w-5 text-white"
                        >
                          <path
                            fill="#3493EF"
                            d="M21,11c-0.6,0-1,0.4-1,1c0,2.9-1.5,5.5-4,6.9c-3.8,2.2-8.7,0.9-10.9-2.9C2.9,12.2,4.2,7.3,8,5.1c3.3-1.9,7.3-1.2,9.8,1.4h-2.4c-0.6,0-1,0.4-1,1s0.4,1,1,1h4.5c0.6,0,1-0.4,1-1V3c0-0.6-0.4-1-1-1s-1,0.4-1,1v1.8C17,3,14.6,2,12,2C6.5,2,2,6.5,2,12s4.5,10,10,10c5.5,0,10-4.5,10-10C22,11.4,21.6,11,21,11z"
                          ></path>
                        </svg>
                      </button>
                    </span>
                  </h1>
                </div>
              </div>
              <div className="flex-1 overflow-auto p-4">
                {isLoading ? (
                  <Loading />
                ) : cart.length === 0 ? (
                  <h1 className="text-xl text-gray-700 font-semibold text-center">
                    {t("NoItemsInCart")}
                  </h1>
                ) : (
                  cart.map((item: any) => {
                    return (
                      <div>
                        <div
                          className={`flex items-center gap-2 cursor-pointer ${
                            item.product.product_availability_count === 0 ||
                            !item.product.product_availability ||
                            item.product.product_availability_count <
                              item.quantity
                              ? "bg-red-100 hover:bg-red-200"
                              : "bg-white"
                          } pl-1 pr-2 rounded-xl hover:bg-gray-100 transition-all duration-200 ease-in-out`}
                          key={item.id}
                        >
                          <div className="flex items-center">
                            <input
                              type="checkbox"
                              className="cursor-pointer disabled:opacity-50 disabled:cursor-not-allowed"
                              disabled={
                                item.product.product_availability_count !== 0 &&
                                item.product.product_availability &&
                                item.product.product_availability_count >=
                                  item.quantity
                                  ? false
                                  : true
                              }
                              checked={cartItems.cartItems.some(
                                (cartitem: any) => cartitem.id === item.id
                              )}
                              onChange={() => {
                                handleCartSelection(item);
                              }}
                            />
                          </div>
                          <div
                            className="flex items-center gap-2 cursor-pointer"
                            onClick={() => {
                              closeCart();
                              navigate(`/product/${item.product.id}`);
                            }}
                          >
                            <div className="flex items-center">
                              <img
                                src={item.product.image_one}
                                alt="Product"
                                className="w-16 h-16 object-cover rounded-2xl truncate"
                              />
                            </div>
                            <div className="flex flex-col">
                              <h1 className="text-lg text-gray-700 font-semibold">
                                {item.product.name}
                                <span>
                                  <div
                                    className={`flex items-center ${
                                      !item.product.product_availability ||
                                      item.product
                                        .product_availability_count === 0 ||
                                      item.product.product_availability_count <
                                        item.quantity
                                        ? "bg-red-400"
                                        : "bg-green-400"
                                    } w-min text-white text-xs px-2 py-1 rounded-lg animate-pulse transition-all duration-200 ease-in-out`}
                                  >
                                    {!item.product.product_availability ||
                                    item.product.product_availability_count ===
                                      0 ||
                                    item.product.product_availability_count <
                                      item.quantity
                                      ? t("OUTSTOCK")
                                      : t("INSTOCK")}
                                  </div>
                                </span>
                              </h1>
                              <div className="flex gap-2">
                                <h1 className="text-md text-gray-700 font-semibold">
                                  ₹{formatCurrency(item.product.price)}
                                </h1>
                                <h1 className="text-md text-gray-700 font-semibold">
                                  {t("Quantity")}: {item.quantity}
                                </h1>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="flex gap-2 mt-1">
                          <button
                            className="flex w-full h-8 bg-red-500 text-white rounded-xl hover:bg-red-600 items-center justify-center"
                            onClick={() => {
                              handleRemoveFromCart(item.id);
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="h-5 w-5"
                              viewBox="0 0 24 24"
                              id="minus-circle"
                            >
                              <path
                                fill="#FFFFFF"
                                d="M12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm0,18a8,8,0,1,1,8-8A8,8,0,0,1,12,20Zm4-9H8a1,1,0,0,0,0,2h8a1,1,0,0,0,0-2Z"
                              ></path>
                            </svg>
                            {t("Remove")}
                          </button>
                          <button
                            className="flex w-full h-8 bg-blue-500 hover:bg-blue-600 text-white rounded-xl items-center justify-center disabled:opacity-50 disabled:cursor-not-allowed"
                            disabled={
                              item.product.product_availability_count !== 0 &&
                              item.product.product_availability &&
                              item.product.product_availability_count >=
                                item.quantity
                                ? false
                                : true
                            }
                            onClick={() => {
                              setIsCartOpen(false);
                              navigate(`/product/${item.product.id}/buy`);
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="h-5 w-5"
                              viewBox="0 0 24 24"
                              id="check"
                            >
                              <path
                                fill="#FFFFFF"
                                d="M18.71,7.21a1,1,0,0,0-1.42,0L9.84,14.67,6.71,11.53A1,1,0,1,0,5.29,13l3.84,3.84a1,1,0,0,0,1.42,0l8.16-8.16A1,1,0,0,0,18.71,7.21Z"
                              ></path>
                            </svg>
                            {t("BuyNow")}
                          </button>
                        </div>
                        <hr className="my-2" />
                      </div>
                    );
                  })
                )}
              </div>
              <div className="flex">
                {cartItems.cartPrice === 0 ? (
                  <div
                    className={`flex w-1/2 h-12 text-center items-center justify-center cursor-not-allowed bg-green-400 text-white rounded-bl-xl font-semibold`}
                  >
                    {t("Checkout")}
                  </div>
                ) : (
                  <Link
                    to="/cart/checkout"
                    className={`flex w-1/2 h-12 text-center items-center justify-center bg-green-500 text-white rounded-bl-xl hover:bg-green-600 font-semibold`}
                    onClick={closeCart}
                  >
                    {t("Checkout")}
                  </Link>
                )}
                <button
                  className="w-1/2 h-12 bg-orange-500 text-white rounded-br-xl font-semibold"
                  disabled={true}
                  onClick={() => {}}
                >
                  {t("Total")}: {formatCurrency(cartItems.cartPrice)}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return null;
  }
}
