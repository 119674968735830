import React, { useState } from "react";
import { useTranslation } from "react-i18next";

const LanguageSelector: React.FC = () => {
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;

  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
    toggleMenu();
  };

  return (
    <div className="relative inline-block text-left">
      <div>
        <button
          type="button"
          onClick={toggleMenu}
          className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
          id="menu-button"
          aria-expanded="true"
          aria-haspopup="true"
        >
          {currentLanguage === "en_US"
            ? "English"
            : currentLanguage === "de"
              ? "German"
              : currentLanguage === "es"
                ? "Spanish"
                : currentLanguage === "hi_IN"
                  ? "Hindi"
                  : currentLanguage === "te_IN"
                    ? "Telugu"
                    : currentLanguage === "ml_IN"
                      ? "Malayalam"
                      : currentLanguage === "ta_IN"
                        ? "Tamil"
                        : "Select Language"}
          <svg
            className="-mr-1 h-5 w-5 text-gray-400"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              fillRule="evenodd"
              d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
              clipRule="evenodd"
            />
          </svg>
        </button>
      </div>

      {isOpen && (
        <div
          className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="menu-button"
          tabIndex={-1}
        >
          <div className="py-1" role="none">
            <button
              className={` block px-4 py-2 text-sm w-full ${currentLanguage === "en_US" ? "text-white bg-blue-400 hover:bg-blue-400" : "text-gray-500"}`}
              role="menuitem"
              tabIndex={-1}
              id="menu-item-0"
              onClick={() => changeLanguage("en_US")}
            >
              English
            </button>
            <button
              className={`block px-4 py-2 text-sm w-full ${currentLanguage === "de" ? "text-white bg-blue-400" : "text-gray-500 hover:bg-blue-100"}`}
              role="menuitem"
              tabIndex={-1}
              id="menu-item-0"
              onClick={() => changeLanguage("de")}
            >
              German
            </button>
            <button
              className={`block px-4 py-2 text-sm w-full ${currentLanguage === "es" ? "text-white bg-blue-400" : "text-gray-500 hover:bg-blue-100"}`}
              role="menuitem"
              tabIndex={-1}
              id="menu-item-0"
              onClick={() => changeLanguage("es")}
            >
              Spanish
            </button>
            <button
              className={`block px-4 py-2 text-sm w-full ${currentLanguage === "hi_IN" ? "text-white bg-blue-400" : "text-gray-500 hover:bg-blue-100"}`}
              role="menuitem"
              tabIndex={-1}
              id="menu-item-0"
              onClick={() => changeLanguage("hi_IN")}
            >
              Hindi
            </button>
            <button
              className={`block px-4 py-2 text-sm w-full ${currentLanguage === "te_IN" ? "text-white bg-blue-400" : "text-gray-500 hover:bg-blue-100"}`}
              role="menuitem"
              tabIndex={-1}
              id="menu-item-0"
              onClick={() => changeLanguage("te_IN")}
            >
              Telugu
            </button>
            <button
              className={`block px-4 py-2 text-sm w-full ${currentLanguage === "ml_IN" ? "text-white bg-blue-400" : "text-gray-500 hover:bg-blue-100"}`}
              role="menuitem"
              tabIndex={-1}
              id="menu-item-0"
              onClick={() => changeLanguage("ml_IN")}
            >
              Malayalam
            </button>
            <button
              className={`block px-4 py-2 text-sm w-full ${currentLanguage === "ta_IN" ? "text-white bg-blue-400" : "text-gray-500 hover:bg-blue-100"}`}
              role="menuitem"
              tabIndex={-1}
              id="menu-item-0"
              onClick={() => changeLanguage("ta_IN")}
            >
              Tamil
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default LanguageSelector;
