/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React from "react";
import { useState, Fragment, useEffect } from "react";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import { UserCircleIcon } from "@heroicons/react/24/outline";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useUserAuth } from "../../Context/UserAuthContext";
import {
  successNotification,
  errorNotification,
} from "../../Notification/Notification";
import useProductsContext from "../../Context/ProductsContext";
import LanguageSelectorDialog from "./ChangeLanguage";
import { useTranslation } from "react-i18next";

const getMenuOptions = () => {
  const userNavigation = {
    loggedout: [{ name: "LogIn", href: "/login" }],
    loggedin: [
      { name: "Orders", href: "/orders" },
      { name: "Profile", href: "/profile" },
      { name: "LogOut", href: "/logout" },
    ],
  };

  return userNavigation;
};

const classNames = (...classes: string[]) => classes.filter(Boolean).join(" ");

interface AppBarProps {
  isCartOpen: boolean;
  setIsCartOpen: (state: boolean) => void;
}
const Appbar = ({ isCartOpen, setIsCartOpen }: AppBarProps) => {
  const { t } = useTranslation();
  const location = useLocation();
  const currentPath = location.pathname;
  const lastPart = currentPath.substring(currentPath.lastIndexOf("/") + 1);
  const [isCartPage, setIsCartPage] = useState<boolean>(false);
  const [menu, setMenu] = useState(getMenuOptions());
  const queryParams = new URLSearchParams(location.search);
  const searchParams = queryParams.get("search");
  const [search, setSearch] = useState(searchParams || "");
  const { user, logout } = useUserAuth();
  const navigate = useNavigate();
  const { getProducts } = useProductsContext();
  let screenType: string | null = null;
  if (1024 < window.innerWidth && window.innerWidth < 1280) {
    screenType = "lg";
  }

  const isHomePage = location.pathname === "/Home"; // Adjust the path as needed

  useEffect(() => {
    const isCartPageCheck = lastPart === "checkout";
    setIsCartPage(isCartPageCheck);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const handelBackButton = () => {
    navigate(-1);
  };

  const handleLogout = () => {
    try {
      logout();
      successNotification("Logged Out Successfully");
      navigate("/login");
    } catch (err: any) {
      errorNotification(err.message.replace("Firebase:", ""));
    }
  };

  const handleSearch = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    // Handle search logic with the query value
    queryParams.delete("page");
    if (search) {
      queryParams.set("search", search);
    } else {
      queryParams.delete("search");
    }
    const newUrl = `${location.pathname}?${queryParams.toString()}`;
    navigate(newUrl);
    await getProducts(1, screenType, search);
  };

  useEffect(() => {
    setMenu(getMenuOptions());
  }, [user]);

  return (
    <>
      <Disclosure
        as="nav"
        className="border-b border-slate-200 bg-gradient-to-tr from-cyan-800 to-cyan-700"
      >
        <div className="mx-auto w-full px-4 py-2">
          <div className="flex h-auto items-center justify-between flex-wrap">
            <div className="flex flex-1 items-start md:items-center flex-col md:flex-row">
              <div className="flex">
                {window.history.length > 1 && !isHomePage && (
                  <button onClick={handelBackButton}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      data-name="Layer 1"
                      viewBox="0 0 24 24"
                      id="arrow-left"
                      className="h-10 w-10"
                    >
                      <path
                        fill="#FFFFFF"
                        d="M17,11H9.41l3.3-3.29a1,1,0,1,0-1.42-1.42l-5,5a1,1,0,0,0-.21.33,1,1,0,0,0,0,.76,1,1,0,0,0,.21.33l5,5a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42L9.41,13H17a1,1,0,0,0,0-2Z"
                      ></path>
                    </svg>
                  </button>
                )}
                <h1
                  className="flex md:text-3xl text-2xl font-semibold text-center pl-4 text-gray-700 dark:text-white cursor-pointer"
                  onClick={() => {
                    document.title = "Vani Sarees - Home";
                    navigate("/");
                  }}
                >
                  Vani Sarees
                </h1>
              </div>
              <div>
                {isHomePage && (
                  <form
                    className="flex items-center justify-center flex-wrap"
                    onSubmit={handleSearch}
                  >
                    <input
                      type="text"
                      value={search}
                      className="border w-auto m-2 rounded-md py-2 px-3 leading-tight focus:outline-none focus:border-blue-500 focus:shadow-outline-blue"
                      onChange={(e) => setSearch(e.currentTarget.value)}
                      placeholder={t("Search") + "..."}
                    />
                    <button
                      className="bg-white m-2 text-gray-700 p-1 rounded font-semibold"
                      type="submit"
                    >
                      {t("Search")}
                    </button>
                  </form>
                )}
              </div>
            </div>

            <div className="flex justify-between w-full sm:w-auto">
              <div className="flex ml-4 w-full  items-center justify-between">
                <div className="mr-1">
                  {user && (
                    <div className="relative">
                      <button
                        className={classNames(
                          `rounded-md px-3 py-2 text-sm font-medium bg-green-500 text-white ${isCartPage ? `animate-pulse ring-2 ring-green-600 ring-opacity-50` : ``}`
                        )}
                        onClick={() => {
                          setIsCartOpen(!isCartOpen);
                        }}
                      >
                        <div className="flex font-sans text-lg items-centerr">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6"
                            viewBox="0 0 24 24"
                            id="shopping-cart-alt"
                          >
                            <path
                              fill="#FFFFFF"
                              d="M21.5,15a3,3,0,0,0-1.9-2.78l1.87-7a1,1,0,0,0-.18-.87A1,1,0,0,0,20.5,4H6.8L6.47,2.74A1,1,0,0,0,5.5,2h-2V4H4.73l2.48,9.26a1,1,0,0,0,1,.74H18.5a1,1,0,0,1,0,2H5.5a1,1,0,0,0,0,2H6.68a3,3,0,1,0,5.64,0h2.36a3,3,0,1,0,5.82,1,2.94,2.94,0,0,0-.4-1.47A3,3,0,0,0,21.5,15Zm-3.91-3H9L7.34,6H19.2ZM9.5,20a1,1,0,1,1,1-1A1,1,0,0,1,9.5,20Zm8,0a1,1,0,1,1,1-1A1,1,0,0,1,17.5,20Z"
                            ></path>
                          </svg>
                          {t("Cart")}
                        </div>
                      </button>
                      {isCartPage && (
                        <p className="absolute w-52 ml-12 top-12 left-1/2 transform -translate-x-1/2 bg-gray-200 p-2 rounded shadow">
                          You can edit the items in the cart to add or remove.
                        </p>
                      )}
                    </div>
                  )}
                </div>
                <div className="flex">
                  <div className="ml-2">
                    <LanguageSelectorDialog />
                  </div>
                  {user ? (
                    <h2 className="text-xl font-medium text-white tracking-tight ml-5">
                      {user ? user.name : null}
                    </h2>
                  ) : null}
                  <Menu as="div" className="relative ml-3 mr-5">
                    <div>
                      <Menu.Button className="rounded-full bg-white p-1 text-gray-400 hover:text-blue-600">
                        <UserCircleIcon
                          className="h-6 w-6"
                          aria-hidden="true"
                          data-testid="user-icon"
                        />
                      </Menu.Button>
                    </div>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white  py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        {user !== null
                          ? menu.loggedin.map((item) => (
                              <Menu.Item key={item.name}>
                                {({ active }) =>
                                  item.name === "Log Out" ? (
                                    <button
                                      onClick={handleLogout}
                                      data-testid="logout-button"
                                      className={classNames(
                                        active ? "bg-gray-100" : "",
                                        "block px-4 py-2 text-sm text-gray-700 w-full text-start"
                                      )}
                                    >
                                      {t(item.name)}
                                    </button>
                                  ) : (
                                    <Link
                                      to={`${item.href}`}
                                      className={classNames(
                                        active ? "bg-gray-100" : "",
                                        "block px-4 py-2 text-sm text-gray-700"
                                      )}
                                    >
                                      {t(item.name)}
                                    </Link>
                                  )
                                }
                              </Menu.Item>
                            ))
                          : menu.loggedout.map((item) => (
                              <Menu.Item key={item.name}>
                                {({ active }) => (
                                  <Link
                                    to={`${item.href}`}
                                    className={classNames(
                                      active ? "bg-gray-100" : "",
                                      "block px-4 py-2 text-sm text-gray-700"
                                    )}
                                  >
                                    {t(item.name)}
                                  </Link>
                                )}
                              </Menu.Item>
                            ))}
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Disclosure>
    </>
  );
};

export default Appbar;
