/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable prefer-const */
import { Dialog, Transition as DialogTansition } from "@headlessui/react";
import { Fragment, useEffect, useState } from "react";
import { Toaster } from "react-hot-toast";
import { useForm, SubmitHandler, useWatch } from "react-hook-form";
import Loading from "../UtilsComponent/Loading";
import { errorNotification } from "../../Notification/Notification";
import { useUserAuth } from "../../Context/UserAuthContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import OtpInput from "react-otp-input";
import PageTitle from "../LayoutComponent/PageTitle";

interface IprofileParams {
  email?: string;
  name?: string;
  phone_number?: string;
  otp?: string;
}

interface profileParams {
  emailValue?: string;
  nameValue?: string;
  phone_numberValue?: string;
  onClose: (name?: string, phone_number?: string) => void;
}

interface IupdateResponse {
  isOpen: boolean;
  msg: string;
  updatedFields?: {
    email?: string;
    name?: string;
    phone_number?: string;
  };
}

const ChangeProfileDialog = ({
  emailValue,
  nameValue,
  phone_numberValue,
  onClose,
}: profileParams) => {
  let [isOpen, setIsOpen] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [showUpdateResponse, setShowUpdateResponse] = useState<IupdateResponse>(
    { isOpen: false, msg: "" }
  );
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [otp, setOtp] = useState("");
  const [TimeOutSeconds, setTimeOutSeconds] = useState(60);
  let timeoutIntervalId: NodeJS.Timeout;

  const { ChangeProfile, SendOTPVerificationCode, SendOTPStatus } =
    useUserAuth();

  const {
    register,
    handleSubmit,
    setError,
    clearErrors,
    control,
    formState: { errors },
  } = useForm<IprofileParams>({
    defaultValues: {
      email: emailValue,
      name: nameValue,
      phone_number: phone_numberValue,
    },
  });

  const phone_number = useWatch({ control, name: "phone_number" });
  const email = useWatch({ control, name: "email" });
  const name = useWatch({ control, name: "name" });

  const handleSendOTP = async () => {
    const response = await SendOTPVerificationCode(phone_number);
    if (response.status === SendOTPStatus.ERROR) {
      Object.keys(response.error).forEach((fieldName: any) => {
        if (fieldName === "non_field_errors") {
          errorNotification(response.errors[fieldName][0]);
        } else {
          setError(fieldName, {
            type: "server",
            message: response.errors[fieldName][0],
          });
        }
      });
      return;
    }
    clearErrors("phone_number");
    setIsOtpSent(true);
    timeoutIntervalId = setInterval(() => {
      setTimeOutSeconds((prevSeconds) => {
        if (prevSeconds > 0) {
          return prevSeconds - 1;
        } else {
          clearInterval(timeoutIntervalId);
          setIsOtpSent(false);
          setOtp("");
          return 60;
        }
      });
    }, 1000);
  };

  const onSubmit: SubmitHandler<IprofileParams> = async (data) => {
    const { email, name, phone_number } = data;
    if (isOtpSent && otp.length !== 4) {
      setError("otp", {
        type: "manual",
        message: "OTP is required",
      });
      return;
    } else {
      clearErrors("otp");
    }
    setIsLoading(true);
    const updateResponse = await ChangeProfile(
      email !== emailValue ? email : undefined,
      name !== nameValue ? name : undefined,
      phone_number !== phone_numberValue ? phone_number : undefined,
      otp.length === 4 ? otp : undefined
    );
    setIsLoading(false);
    if (updateResponse.status) {
      setShowUpdateResponse({
        isOpen: true,
        msg: updateResponse.msg,
        updatedFields: updateResponse.updatedFields,
      });
    } else {
      Object.keys(updateResponse.error).forEach((fieldName: any) => {
        if (fieldName === "non_field_errors") {
          errorNotification(updateResponse.error[fieldName][0]);
        } else {
          if (fieldName === "OTP") {
            setError("otp", {
              type: "server",
              message: updateResponse.error[fieldName][0],
            });
          } else {
            setError(fieldName, {
              type: "server",
              message: updateResponse.error[fieldName][0],
            });
          }
        }
      });
    }
  };

  useEffect(() => {
    return () => {
      clearInterval(timeoutIntervalId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function closeModal() {
    setIsOpen(false);
    setIsOtpSent(false);
    setOtp("");
    setTimeOutSeconds(60);
    clearInterval(timeoutIntervalId);
    onClose(
      showUpdateResponse.updatedFields?.name,
      showUpdateResponse.updatedFields?.phone_number
    );
  }

  const formattedTime = `${Math.floor(TimeOutSeconds / 60)}:${(
    TimeOutSeconds % 60
  )
    .toString()
    .padStart(2, "0")}`;

  if (isLoading) return <Loading />;

  return (
    <>
      <PageTitle pageTitle="Change Profile" />
      <DialogTansition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="fixed inset-0 z-100" onClose={closeModal}>
          {/* Overlay */}
          <DialogTansition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </DialogTansition.Child>

          {/* Modal Content */}
          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <DialogTansition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="flex font-bold text-2xl leading-6 items-center mb-2 text-gray-700"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      id="edit"
                      className="h-6 w-6 mr-2 fill-current text-gray-700"
                    >
                      <path
                        fill="#22B9FF"
                        d="M21,12a1,1,0,0,0-1,1v6a1,1,0,0,1-1,1H5a1,1,0,0,1-1-1V5A1,1,0,0,1,5,4h6a1,1,0,0,0,0-2H5A3,3,0,0,0,2,5V19a3,3,0,0,0,3,3H19a3,3,0,0,0,3-3V13A1,1,0,0,0,21,12ZM6,12.76V17a1,1,0,0,0,1,1h4.24a1,1,0,0,0,.71-.29l6.92-6.93h0L21.71,8a1,1,0,0,0,0-1.42L17.47,2.29a1,1,0,0,0-1.42,0L13.23,5.12h0L6.29,12.05A1,1,0,0,0,6,12.76ZM16.76,4.41l2.83,2.83L18.17,8.66,15.34,5.83ZM8,13.17l5.93-5.93,2.83,2.83L10.83,16H8Z"
                      ></path>
                    </svg>
                    <h1 className="text-blue-500 font-semibold">
                      {showUpdateResponse.isOpen
                        ? "Update Status"
                        : "Change Profile"}
                    </h1>
                  </Dialog.Title>
                  {!showUpdateResponse.isOpen ? (
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <div className="sm:flex">
                        <div className="flex flex-col max-w-1/2 sm:w-full mt-8 p-4 bg-white rounded shadow m-2 justify-between">
                          <div className="mb-4">
                            <label
                              htmlFor="name"
                              className="block text-gray-700"
                            >
                              Name:
                            </label>
                            <input
                              type="text"
                              id="billing_customer_name"
                              placeholder="Enter name..."
                              autoFocus
                              {...register("name", {
                                required: true,
                              })}
                              className={`w-full border rounded-md py-2 px-3 my-4  leading-tight focus:outline-none focus:border-blue-500 focus:shadow-outline-blue ${
                                errors.name ? "border-red-500" : ""
                              }`}
                            />
                            {errors.name && (
                              <span className="text-red-500">
                                {errors.name.message ||
                                  "This field is required"}
                              </span>
                            )}
                          </div>

                          <div className="mb-4">
                            <label
                              htmlFor="email"
                              className="block text-gray-700"
                            >
                              Email:
                            </label>
                            <input
                              type="text"
                              id="billing_email"
                              placeholder="Enter email..."
                              {...register("email", { required: true })}
                              className={`w-full border rounded-md py-2 px-3 my-4  leading-tight focus:outline-none focus:border-blue-500 focus:shadow-outline-blue ${
                                errors.email ? "border-red-500" : ""
                              }`}
                            />
                            {errors.email && (
                              <span className="text-red-500">
                                {errors.email.message ||
                                  "This field is required"}
                              </span>
                            )}
                          </div>

                          <div className="mb-4">
                            <label
                              htmlFor="phoneNumber"
                              className="block text-gray-700"
                            >
                              Phone Number:
                            </label>
                            <div className="flex items-center">
                              <span className="mr-1 text-gray-700">+91</span>
                              <input
                                type="text"
                                id="phone_number"
                                placeholder="Enter phone number..."
                                disabled={isOtpSent}
                                {...register("phone_number", {
                                  required: false,
                                })}
                                className={`w-full border rounded-md py-2 px-3 my-4 leading-tight focus:outline-none focus:border-blue-500 focus:shadow-outline-blue ${
                                  errors.phone_number ? "border-red-500" : ""
                                }`}
                              />

                              <button
                                type="button"
                                className="bg-blue-500 h-min p-2 ml-2 text-white rounded font-semibold disabled:bg-blue-300 disabled:cursor-not-allowed"
                                disabled={
                                  phone_number === phone_numberValue ||
                                  phone_number?.length !== 10 ||
                                  isOtpSent
                                }
                                onClick={handleSendOTP}
                              >
                                Send OTP
                              </button>
                            </div>
                            {errors.phone_number && (
                              <span className="text-red-500">
                                {errors.phone_number.message ||
                                  "This field is required"}
                              </span>
                            )}
                          </div>

                          {isOtpSent && (
                            <div className="mb-4">
                              <label
                                htmlFor="phoneNumber"
                                className="flex text-gray-700 mb-2"
                              >
                                OTP:
                                <div>
                                  <p className="text-gray-700 ml-5">
                                    Time Remaining: {formattedTime}
                                  </p>
                                </div>
                              </label>
                              <div className="flex justify-center">
                                <OtpInput
                                  value={otp}
                                  onChange={setOtp}
                                  numInputs={4}
                                  renderSeparator={
                                    <span className="m-2">-</span>
                                  }
                                  inputStyle={
                                    "h-11 justify-center items-center border border-emerald-700 rounded-md leading-tight focus:outline-none focus:border-emerald-500 "
                                  }
                                  renderInput={(props) => (
                                    <input
                                      {...props}
                                      style={{
                                        width: "2em",
                                        textAlign: "center",
                                      }}
                                    />
                                  )}
                                />
                              </div>
                              {errors.otp && (
                                <span className="text-red-500">
                                  {errors.otp.message ||
                                    "This field is required"}
                                </span>
                              )}
                            </div>
                          )}

                          <button
                            type="submit"
                            className="bg-blue-500 text-white px-4 py-2 rounded font-semibold disabled:bg-blue-300 disabled:cursor-not-allowed"
                            disabled={
                              !(
                                email !== emailValue ||
                                name !== nameValue ||
                                (isOtpSent && otp.length === 4)
                              )
                            }
                          >
                            Update
                          </button>
                        </div>
                      </div>
                    </form>
                  ) : (
                    <div>
                      <div className="flex flex-col justify-start m-1">
                        {showUpdateResponse?.updatedFields?.name && (
                          <div className="flex items-center justify-start m-1">
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              className="text-green-500 text-5xl"
                            />
                            <p className="text-green-500 text-2xl ml-1">
                              Name is updated
                            </p>
                          </div>
                        )}

                        {showUpdateResponse?.updatedFields?.email && (
                          <div className="flex items-center justify-start m-1">
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              className="text-green-500 text-5xl"
                            />
                            <p className="text-green-500 text-2xl ml-1">
                              Sent Email Verification Check your mail
                            </p>
                          </div>
                        )}

                        {showUpdateResponse?.updatedFields?.phone_number ===
                          phone_number && (
                          <div className="flex items-center justify-start m-1">
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              className="text-green-500 text-5xl"
                            />
                            <p className="text-green-500 text-2xl ml-1">
                              Phone is Updated
                            </p>
                          </div>
                        )}

                        {showUpdateResponse?.updatedFields?.phone_number !==
                          undefined &&
                          showUpdateResponse?.updatedFields?.phone_number !==
                            phone_number && (
                            <div className="flex items-center justify-start m-1">
                              <FontAwesomeIcon
                                icon={faTimesCircle}
                                className="text-red-500 text-5xl"
                              />
                              <p className="text-red-500 text-2xl ml-1">
                                Phone number is used by another user
                              </p>
                            </div>
                          )}
                        <div className="flex w-full justify-center">
                          <button
                            className="bg-blue-500 text-white px-8 py-2 rounded font-semibold w-fit mt-4"
                            onClick={closeModal}
                          >
                            Close
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </Dialog.Panel>
              </DialogTansition.Child>
            </div>
          </div>

          {/* Toast Notification */}
          <Toaster />
        </Dialog>
      </DialogTansition>
    </>
  );
};

export default ChangeProfileDialog;
