import { Outlet } from "react-router-dom";
import Appbar from "./Appbar";
import { useState } from "react";
import Cart from "../CartComponent/Cart";
import UserProfile from "../ProfileComponent/UserProfile";
import Footer from "./FooterSection";
import PageTitle from "./PageTitle";

const ProfileLayout = () => {
  const [isCartOpen, setIsCartOpen] = useState(false);

  return (
    <>
      <PageTitle pageTitle="Profile" />
      <Appbar isCartOpen={isCartOpen} setIsCartOpen={setIsCartOpen} />
      <main>
        <UserProfile />
        <Outlet />
        <Cart isCartOpen={isCartOpen} setIsCartOpen={setIsCartOpen} />
      </main>
      <Footer />
    </>
  );
};
export default ProfileLayout;
