import React, { useEffect } from "react";

interface Props {
  pageTitle: string;
}

const PageTitle: React.FC<Props> = ({ pageTitle }) => {
  useEffect(() => {
    document.title = "Vani Sarees - " + pageTitle;
  }, [pageTitle]);

  return null; // This component doesn't render anything to the DOM
};

export default PageTitle;
