/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from "react";
import { useUserAuth } from "../../Context/UserAuthContext";
import Loading from "../UtilsComponent/Loading";
import { errorNotification } from "../../Notification/Notification";
import { useNavigate } from "react-router-dom";
import ChangeProfileDialog from "./ChangeProfileDialog";

interface UserProfileProps {
  id: string;
  email: string;
  name: string;
  phone_number?: string;
  is_email_verified: boolean;
  is_phone_number_verified: boolean;
}

const UserProfile = () => {
  const [userProfile, setUserProfile] = useState<UserProfileProps | null>(null);
  const [isEmailVerifying, setIsEmailVerifying] = useState<boolean>(false);
  const { getProfile, SendEmailVerification } = useUserAuth();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isProfileDialogOpen, setIsProfileDialogOpen] =
    useState<boolean>(false);

  const navigate = useNavigate();

  const handleVerifyEmail = async () => {
    setIsEmailVerifying(true);
    const response = await SendEmailVerification();
    if (response !== true) {
      Object.keys(response.errors).forEach((fieldName: any) => {
        if (fieldName === "non_field_errors") {
          errorNotification(response.errors[fieldName][0]);
        }
      });
    }
    setIsEmailVerifying(false);
  };

  const fetchProfile = async () => {
    setIsLoading(true);
    const profile = await getProfile();
    setUserProfile(profile);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchProfile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading) {
    return (
      <div>
        <Loading />
      </div>
    );
  }

  return (
    <div className="min-h-screen max-w-4xl mx-auto p-4">
      {isProfileDialogOpen && (
        <ChangeProfileDialog
          emailValue={userProfile?.email}
          nameValue={userProfile?.name}
          phone_numberValue={userProfile?.phone_number}
          onClose={(name?: string, phone_number?: string) => {
            if (name) {
              setUserProfile((prevProfile) => ({
                ...prevProfile!,
                name: name,
              }));
            }
            if (phone_number) {
              setUserProfile((prevProfile) => ({
                ...prevProfile!,
                phone_number: phone_number,
                is_phone_number_verified: true,
              }));
            }
            setIsProfileDialogOpen(false);
          }}
        />
      )}
      <h1 className="text-3xl font-semibold mb-4">Profile</h1>
      <div className="border p-4 mb-4">
        <p>
          <span className="font-semibold">Email:</span> {userProfile?.email}
        </p>
        <p>
          <span className="font-semibold">Name:</span> {userProfile?.name}
        </p>
        <p>
          <span className="font-semibold">Phone Number:</span>{" "}
          {userProfile?.phone_number || "Not provided"}
        </p>
        <p>
          <span className="font-semibold">Email Verified:</span>{" "}
          {userProfile?.is_email_verified ? "Yes" : "No"}
          {userProfile?.is_email_verified ? null : (
            <button
              className="m-2 bg-blue-500 text-white px-2 py-1 rounded hover:bg-blue-600 focus:outline-none"
              onClick={handleVerifyEmail}
              disabled={isEmailVerifying}
            >
              {isEmailVerifying ? "Sending Email..." : "Verify Email"}
            </button>
          )}
        </p>
        <p>
          <span className="font-semibold">Phone Number Verified:</span>{" "}
          {userProfile?.is_phone_number_verified ? "Yes" : "No"}
        </p>
      </div>
      <div className="flex flex-wrap">
        <button
          className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 focus:outline-none ml-5 mb-2"
          onClick={fetchProfile}
        >
          Refresh Verification Status
        </button>
        <button
          className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-600 focus:outline-none ml-5 mb-2"
          onClick={() => navigate("/changepassword")}
        >
          Change Password
        </button>
        <button
          className="bg-emerald-500 text-white px-4 py-2 rounded hover:bg-emerald-600 focus:outline-none ml-5 mb-2"
          onClick={() => setIsProfileDialogOpen(true)}
        >
          Change Profile
        </button>
      </div>
    </div>
  );
};

export default UserProfile;
